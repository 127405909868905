@media (max-width: 870px) {
    .portrait {
        width: 306px;
        height: 408px;
        flex: 1;
        align-self: center;
    }
    .SmallText {
        flex: 2;
        margin-left: 10px;
        font-size: 15px;
    }
    .about-flex {
        margin-top: 30px;
        display: flex;
        flex-direction: row;
    }
}
@media (max-width: 740px) {
    .portrait {
        width: 153px;
        height: 204px;
        flex: 1;
        align-self: center;
    }
}
@media (min-width: 871px) {
    .portrait {
        max-width: 306px;
        height: 408px;
        flex: 1;
        /* align-self: center; */
    }
    .SmallText {
        flex: 2;
        margin-left: 20px;
        margin-top: 0;
    }
    .about-flex {
        margin-top: 30px;
        display: flex;
        flex-direction: row;
    }
}

.Subtitle2 {
    margin-bottom: 0;
}