@media (max-width: 480px) {
    .Preview-container {
        display:flex;
        flex-direction: row;
        border:1px solid black;
        border-radius:10px;
        margin-bottom: 20px;
        min-width: 60vw;
        max-width: 60vw;
        padding: 20px;
        flex-wrap: wrap;
        margin-left: 10px;
        margin-right: 10px;
    }
    .Preview-text {
        flex: 4;
        margin-left: 10px;
        margin-right: 10px;
    }

    .Preview-description {
        overflow-wrap:anywhere;
        white-space: pre-wrap;
        font-size: 13px;
    }
    .Preview-image {
        flex:1;
        margin-left: 10px;
        margin-right: 10px;
    }
    .Project-icons {
        /*object-fit: contain;*/
        width: 100px;
        height: 100px;
    }
    .Preview-title {
        font-size:20px;
        margin-bottom: 3px;
    }

    .Preview-subtext {
        font-size:15px
    }
}
@media (min-width: 481px) {
    .Preview-container-titles {
        display:flex;
        flex-direction: row;
        flex-wrap: wrap;
    }
    .Preview-container {
        
        border:1px solid black;
        border-radius:10px;
        margin-bottom: 20px;
        min-width: 50vw;
        max-width: 50vw;
        padding: 20px;
    }
    .Preview-text {
        flex: 5;
        margin-left: 10px;
        margin-right: 10px;
        align-self: center;
    }

    .Preview-description {
        overflow-wrap:anywhere;
        white-space: pre-wrap;
    }
    .Preview-image {
        flex:.5;
        margin-left: 10px;
        margin-right: 10px;
        align-self: center;
    }
    .Project-icons {
        object-fit: contain;
        width: 100px;
        height: 100px;
    }
    .Preview-title {
        font-size:25px
    }

    .Preview-subtext {
        font-size:16px
    }
}

.Preview-container:hover{
    animation: hoverAnimation ease .1s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
}
@keyframes hoverAnimation {
    100% {
        transform: translate3d(-3px,-7px,0);
        box-shadow: 0px 15px 18px 0px rgb(0, 0, 0, 0.12);
    }
}

